<template>
  <div>
    <SmartNotify v-if="!isNewVersion" />
    <SmartNotifyV2 v-if="isNewVersion" />
  </div>
</template>

<script>
import Vue from 'vue'
import SmartNotify from '@/module/SmartNotify/SmartNotify.vue'
import SmartNotifyV2 from '@/module/SmartNotify/SmartNotifyV2.vue'
export default {
  name: 'Main',
  components: {
    SmartNotify,
    SmartNotifyV2,
  },
  data: function () {
    return {
      isNewVersion: false,
    }
  },
  created() {
    this.isNewVersion = Vue.bzbsConfig.businessRule.open.smarttarget_v2
  },
}
</script>
