<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="smart-notify">
          <div class="row border-smart">
            <div class="col-lg-1 col-12">
              <div class="icon-alert">
                <img src="@/assets/images/icon_notification.png" alt="" />
              </div>
            </div>
            <div class="col-lg-11 col-10 pl-0 box-smart">
              <div class="head-title">
                <h1 class="title-smart">{{ lbl['main-smart-notify'] }}</h1>
                <span class="new">{{ lbl['new'] }}</span>
              </div>
              <p class="detail-smart">{{ lbl['encourage-target'] }}</p>
            </div>
            <div class="col-12 border-cutom"></div>
          </div>
        </div>
        <div class="title mb-3">{{ lbl['smart-target-selecttargethere'] }}</div>
        <div v-if="isDisable" class="please-connect-line">
          {{ lbl['smarttarget-please-connect-line'] }}
        </div>
        <div class="targetBoxContainer">
          <div
            class="targetBox"
            v-for="item in targetBoxData"
            :key="item.header"
            :class="{ 'disable-box': isDisable }"
            @click="selectedTarget(item)"
          >
            <div :class="['targetIcon']" :style="item.style">
              <img
                width="26"
                height="26"
                :src="require(`@/assets/images/${item.icon}.png`)"
                alt=""
              />
            </div>
            <div class="targetDetail">
              <div class="header">
                {{ item.heading[currentLocale] }}
              </div>
              <div class="subHeader">
                {{ item.description[currentLocale] }}
              </div>
            </div>
          </div>
        </div>
        <div class="title mt-5">{{ lbl['smart-target-list'] }}</div>
        <div class="subTitle">
          {{ lbl['smart-target-createdsmartcampaign'] }}
        </div>

        <div class="filterContainer">
          <div :class="['filterMain', showFilterMain ? 'active' : '']">
            <div class="filter">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item.text">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item.text, subFilter)"
                  >
                    <a-checkbox
                      :checked="filter[subFilter].value.includes(item.text)"
                    >
                      {{ item.text }}
                    </a-checkbox>
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="filteredDataList">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in filterItem"
            :key="'filter' + index"
          >
            {{ item
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>
        <div class="tableContainer">
          <a-skeleton v-if="loading.list" active />
          <a-table
            v-if="!loading.list"
            :columns="columns"
            :data-source="smartTargetListFilter"
            @change="onchangeTable"
            :pagination="{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
              pageSizeOptions: pageSizeOptions,
              pageSize: defaultPageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
            }"
            rowKey="id"
          >
            <template slot="action" slot-scope="text, record">
              <div
                class="
                  action-list
                  fs-16
                  text-left
                  d-flex
                  justify-content-center
                "
              >
                <a-tooltip v-if="record.status === 'draft'" placement="top">
                  <template slot="title"
                    ><div class="mt-1">
                      {{ lbl['smart-target-edit'] }}
                    </div></template
                  >
                  <router-link
                    :to="{
                      name: 'InactiveUser',
                      query: {
                        mode: 'edit',
                        id: record.id,
                        target: record.target,
                        sent: record.sent,
                        redeemed: record.redeemed,
                        used: record.used,
                      },
                    }"
                    class="mx-2"
                  >
                    <a-icon
                      @click="setItem(record.userListItem)"
                      :style="{ color: '#757575', fontSize: '18px ' }"
                      type="form"
                    />
                  </router-link>
                </a-tooltip>
                <a-tooltip v-if="record.status === 'draft'" placement="top">
                  <template slot="title"
                    ><div class="mt-1">
                      {{ lbl['fgf-buttoncancel'] }}
                    </div></template
                  >
                  <div class="mx-2">
                    <a-icon
                      :style="{ color: '#ff5252', fontSize: '18px ' }"
                      theme="filled"
                      type="delete"
                      @click="toggleDeleteModal(true, record.id)"
                    />
                  </div>
                </a-tooltip>
                <a-tooltip v-if="record.status !== 'draft'" placement="top">
                  <template slot="title"
                    ><div class="mt-1">
                      {{ lbl['smart-target-view'] }}
                    </div></template
                  >
                  <router-link
                    :to="{
                      name: 'InactiveUser',
                      query: {
                        mode: 'view',
                        id: record.id,
                        target: record.target,
                        sent: record.sent,
                        redeemed: record.redeemed,
                        used: record.used,
                      },
                    }"
                    class="mx-2"
                  >
                    <a-icon
                      @click="setItem(record.userListItem)"
                      :style="{ color: '#2196f3', fontSize: '18px ' }"
                      theme="filled"
                      type="eye"
                    />
                  </router-link>
                </a-tooltip>
                <a-tooltip v-if="record.status === 'created'" placement="top">
                  <template slot="title"
                    ><div class="mt-1">
                      {{ lbl['fgf-buttoncancel'] }}
                    </div></template
                  >
                  <div class="mx-2">
                    <a-icon
                      :style="{ color: '#ff5252', fontSize: '18px ' }"
                      theme="filled"
                      type="delete"
                      @click="toggleDeleteModal(true, record.id)"
                    />
                  </div>
                </a-tooltip>
              </div>
            </template>

            <template slot="status" slot-scope="text, record">
              <div class="statusContainer d-flex">
                <div :class="['statusIcon mt-1', record.statusLable]"></div>
                <div class="detail text-left">
                  <div class="date">
                    {{ record.date ? formatDateString(record.date) : '-' }}
                  </div>
                  <div class="status">{{ record.statusLable }}</div>
                </div>
              </div>
            </template>

            <template slot="reward" slot-scope="text, record">
              <div class="rewardContainer d-flex">
                <div v-if="record.imgPreview" class="rewardImg">
                  <img :src="record.imgPreview" class="w-100 h-100" alt="" />
                </div>
                <div class="rewardDetail text-left">
                  {{ record.reward }}
                </div>
              </div>
            </template>

            <template slot="userTarget" slot-scope="text, record">
              <div class="userTargetContainer d-flex">
                <div
                  :class="['userTargetItem']"
                  :style="record.userListItem[0].style"
                >
                  {{ record.userListItem[0].filtertext }}
                </div>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <DeleteModal
      :toggle="toggleDeleteModal"
      :isDeleteModal="isDeleteModal"
      :cfDelete="deleteCampiagn"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSmarttarget from '@/core/Smarttarget/service/BzbsSmarttarget'
import DeleteModal from './modal/DeleteModal'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import moment from 'moment'
import Locale from '@/helper/locale.js'
export default {
  name: 'importUserSearch',
  components: {
    Header,
    DeleteModal,
  },
  mixins: [Mixin],
  data: function () {
    return {
      targetBoxData: [],
      subFilter: 'status',
      showFilterMain: false,
      totalSmartTargetList: 0,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      filterValue: [],
      filter: {
        status: {
          value: [],
          input: [
            { text: 'All Status', value: 'All Status' },
            { text: 'Draft', value: 'draft' },
            { text: 'Publish', value: 'publish' },
            { text: 'Cancelled', value: 'cancelled' },
            { text: 'Created', value: 'created' },
          ],
          label: 'Status',
          type: 'checkbox',
        },
        target: {
          value: [],
          input: [{ text: 'All Target', value: 'All Target' }],
          label: 'Target',
          type: 'checkbox',
        },
      },
      columns: [
        {
          title: 'Send date & Status',
          dataIndex: 'status',
          key: 'status',
          width: 220,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Reward',
          dataIndex: 'reward',
          key: 'reward',
          width: 280,
          scopedSlots: { customRender: 'reward' },
        },
        {
          title: 'User Target',
          dataIndex: 'userTarget',
          key: 'userTarget',
          scopedSlots: { customRender: 'userTarget' },
        },
        {
          title: 'Target',
          dataIndex: 'target',
          key: 'target',
          align: 'right',
        },
        {
          title: 'Sent',
          dataIndex: 'sent',
          key: 'sent',
          align: 'right',
        },
        {
          title: 'Unique Click',
          dataIndex: 'click',
          key: 'click',
          align: 'right',
        },
        {
          title: 'Redeemed',
          dataIndex: 'redeemed',
          key: 'redeemed',
          align: 'right',
        },
        {
          title: 'Used',
          dataIndex: 'used',
          key: 'used',
          align: 'right',
        },
        // {
        //   title: 'Cost',
        //   dataIndex: 'cost',
        //   key: 'cost',
        // },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      smartTargetList: [],
      smartTargetListFilter: [],
      isDeleteModal: false,
      loading: { list: false },
      selectedDeleteId: '',
      currentPage: 1,
      currentLocale: 'th',
      filterItem: [],
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
    }
  },
  computed: {
    allFilter() {
      return [...this.filter.status.value, ...this.filter.target.value]
    },
    isDisable() {
      if (this.loginType == 'Line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
    dataDetailPerPage() {
      let lenght = this.smartTargetListFilter.length
      let lastItemPage = lenght % 10
      let lastPage = Math.ceil(lenght / 10)
      if (this.currentPage === lastPage) {
        return lastItemPage
      }
      return lenght < 10 ? lenght : 10
    },
  },
  created() {
    Account.bzbsAnalyticTracking(
      'smart_target_page',
      'smart_target',
      'view_smart_target',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.getSmarttargetBlob()
      this.getSmarttargetList()
      this.getCRMPlusProfile().then(result => {
        this.loginType = result.LoginType
        this.appId = result.AppId
        this.liffId = result.LiffId
        this.liffLanding = result.LiffLanding
      })
      this.currentLocale = Locale.getLocaleShort()
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    onchangeTable(e) {
      this.currentPage = e.current
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    setItem(data) {
      console.log(data)
      sessionStorage.setItem('target', JSON.stringify(data[0]))
    },
    selectFilter(item, type) {
      console.log(item)
      if (this.filter[type].value.includes(item)) {
        this.filter[type].value = this.filter[type].value.filter(
          fItem => fItem !== item,
        )
      } else {
        if (item === 'All Status' || item === 'All Target') {
          this.filter[type].value = []
        } else {
          let deleteType = 'All Target'
          if (type === 'status') deleteType = 'All Status'
          if (this.filter[type].value.indexOf(deleteType) > -1) {
            this.filter[type].value.splice(
              this.filter[type].value.indexOf(deleteType),
              1,
            )
          }
        }
        this.filter[type].value.push(item)
      }
    },
    removeFilter(item) {
      let type = 'target'
      if (this.filter.status.value.includes(item)) type = 'status'
      if (item !== 'All Status' || item !== 'All Target') {
        this.filter[type].value = this.filter[type].value.filter(
          fItem => fItem !== item,
        )
      }
      // if (this.filter[type].value.length === 0) {
      //   if (type === 'status') {
      //     this.filter[type].value.push('All Status')
      //   } else {
      //     this.filter[type].value.push('All Target')
      //   }
      // }
      this.filteredDataList()
    },
    resetFilter() {
      this.filter.status.value = []
      this.filter.target.value = []
      this.filteredDataList()
    },
    filteredDataList() {
      this.filterItem = [...this.allFilter]
      let filterlength = [...this.allFilter].length
      let filterr = []
      Account.bzbsAnalyticTracking(
        'smart_target_page',
        'smart_target',
        'click_smart_filter',
        'on click',
      )
      if (
        (this.allFilter.includes('All Target') &&
          this.allFilter.includes('All Status')) ||
        filterlength === 0
      ) {
        console.log(1)
        filterr = this.smartTargetList
      } else if (
        this.allFilter.includes('All Target') &&
        !this.allFilter.includes('All Status')
      ) {
        if (filterlength === 1) {
          filterr = this.smartTargetList
        } else {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.statusLable)
          })
        }
      } else if (
        !this.allFilter.includes('All Target') &&
        this.allFilter.includes('All Status')
      ) {
        if (filterlength === 1) {
          filterr = this.smartTargetList
        } else {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.userListItem[0].filtertext)
          })
        }
      } else {
        if ([...this.filter.target.value].length === 0) {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.statusLable)
          })
        } else if ([...this.filter.status.value].length === 0) {
          filterr = this.smartTargetList.filter(f => {
            return this.allFilter.includes(f.userListItem[0].filtertext)
          })
        } else {
          filterr = this.smartTargetList.filter(f => {
            return (
              this.allFilter.includes(f.userListItem[0].filtertext) &&
              this.allFilter.includes(f.statusLable)
            )
          })
        }
      }

      this.smartTargetListFilter = filterr
      console.log('filterr', filterr)
      console.log('this.smartTargetList', this.smartTargetListFilter)
      this.totalSmartTargetList = this.smartTargetListFilter.length
    },
    formatDateString(dateString, format = 'DD/MM/YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    toggleDeleteModal(type, id) {
      this.isDeleteModal = type
      this.selectedDeleteId = id
    },
    selectedTarget(data) {
      if (!this.isDisable) {
        Account.bzbsAnalyticTracking(
          'smart_target_page',
          'smart_target',
          'click_smart_target_create_' + data.name,
          'on click',
        )
        this.$store.dispatch('setSelectedSmartTarget', data)
        sessionStorage.setItem('target', JSON.stringify(data))
        this.$router.push({
          name: 'InactiveUser',
          query: { mode: 'create', id: data.name },
        })
      }
    },
    getSmarttargetBlob() {
      BzbsSmarttarget.getSmarttargetBlob()
        .then(res => {
          console.log('blob', res)
          Account.bzbsAnalyticTracking(
            'smart_target_page',
            'smart_target',
            'view_smart_select_target',
            'on view',
          )
          this.targetBoxData = res.data
          let list = res.data.map(t => {
            return t.filtertext
          })
          let newlist = [...new Set(list)]
          newlist.forEach(item => {
            this.filter.target.input.push({ text: item, value: item })
          })
        })
        .catch(err => console.log(err))
    },
    getSmarttargetList() {
      this.loading.list = true
      BzbsSmarttarget.getSmarttargetList(
        [...this.filter.status.value],
        [...this.filter.target.value],
      )
        .then(res => {
          console.log('getSmarttargetList', res)
          Account.bzbsAnalyticTracking(
            'smart_target_page',
            'smart_target',
            'view_smart_list',
            'on view',
          )
          this.smartTargetList = res.data.data
            .sort((a, b) => b.Id - a.Id)
            .map(item => {
              let target = ''
              let targetLable = ''
              let userListItem = {}
              userListItem = this.targetBoxData.filter(i => {
                return item.UserTarger == i.name
              })
              let imgPreview = ''
              let jsonData = {}
              if (item.LineTemplate) {
                if (item.LineTemplate.LineSSTemplateActions.length == 2) {
                  jsonData = JSON.parse(
                    item.LineTemplate.LineSSTemplateActions[1].Json,
                  )
                }
              }
              if (jsonData.baseUrl) {
                imgPreview = jsonData.baseUrl + '/240'
              }
              let obj = {
                id: item.Id,
                status: item.Status,
                statusLable:
                  item.Status[0].toUpperCase() + item.Status.substring(1),
                date: new Date(item.SendDate * 1000),
                reward: item.Name,
                userTarget: target,
                targetLable,
                target: item.MarketingCampaignResult.TotalSent,
                sent:
                  parseInt(item.MarketingCampaignResult.CountSent) >= 0
                    ? item.MarketingCampaignResult.CountSent
                    : '-',
                imgPreview,
                click: item.MarketingCampaignResult.CountClick,
                redeemed: item.MarketingCampaignResult.CountRedeem,
                used: item.MarketingCampaignResult.CountUsed,
                cost: '-',
                userListItem,
              }
              let sendDateParse = Date.parse(
                moment(new Date(item.SendDate * 1000)).add(-7, 'hours'),
              )
              if (
                sendDateParse > Date.parse(new Date()) &&
                obj.status !== 'cancelled'
              ) {
                obj.status = 'created'
                obj.statusLable = 'Created'
              }
              return obj
            })
          this.smartTargetListFilter = this.smartTargetList
          console.log(this.smartTargetList)
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading.list = false))
    },
    deleteCampiagn() {
      this.handleLoading(true)
      BzbsSmarttarget.deleteCampiagn(this.selectedDeleteId)
        .then(res => {
          console.log(res)
          this.toggleDeleteModal(false, '')
          this.getSmarttargetList()
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/smartnotify.scss';
.container-fluid {
  margin-top: 110px;
  background-size: cover !important;
  background-attachment: fixed;
  background-position: center;
}
</style>
<style lang="scss">
.tableContainer {
  .ant-table .ant-table-tbody > tr > td {
    border-left: none !important;
    font-size: 14px !important;
    text-align: left;
    padding-left: 16px;
  }
  .ant-table .ant-table-tbody > tr > td:last-child {
    border-right: none !important;
  }
  .ant-pagination-item-active {
    border-color: #ff9800;
    a {
      color: white !important;
      background-color: #ff9800;
    }
  }
  .ant-pagination.ant-table-pagination {
    display: flex;
    width: 100%;
    .ant-pagination-total-text {
      margin-right: auto;
    }
    .ant-pagination-disabled.ant-pagination-prev {
      margin-left: auto;
    }
  }
}
.filterList {
  .ant-checkbox-wrapper {
    pointer-events: none;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff9800;
    border-color: #ff9800;
  }
}
.ant-pagination-options {
  .ant-select-selection {
    height: 32px !important;
    .ant-select-selection__rendered {
      line-height: 32px !important;
    }
  }
}
.anticon-right,
.anticon-left {
  svg {
    margin-bottom: 5px;
  }
}
@media only screen and (max-device-width: 821px) {
  .targetBoxContainer {
    padding: 0px !important;
    gap: 10px !important;
  }
}
</style>
